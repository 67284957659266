"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var methods_1 = require("../methods");
var component_will_unmount_1 = require("./component-will-unmount");
var component_will_update_1 = require("./component-will-update");
function bindLifecycleMethods(that) {
    if (!component_will_unmount_1.componentWillUnmountPrototype(that)) {
        that.componentWillUnmount = function () {
            methods_1.ReactNComponentWillUnmount(that);
        };
    }
    if (!component_will_update_1.componentWillUpdatePrototype(that)) {
        that.componentWillUpdate = function () {
            methods_1.ReactNComponentWillUpdate(that);
        };
    }
}
exports.default = bindLifecycleMethods;
;
